class Notification {
    /**
     * @param {HTMLElement} element
     */
    
    constructor(element) {
        this.element = element;
        this._body   = '';
        this.body    = this.element.innerHTML;

        this.closeBtn = null;
        this.titleTag = null;
        this.activeTimeout = null;
        this.previousFocus = null;
    }

    get title() {
        return this.element.getAttribute('title');
    }

    get timeout() {
        return this.element.getAttribute('timeout');
    }

    get close() {
        return this.element.getAttribute('close');
    }

    get body() {
        return this._body;
    }

    set body(value) {
        this._body = value;
    }

    get target() {
        return document.querySelector(this.targetName);
    }

    get targetName() {
        return this.element.getAttribute('target');
    }

    hide() {
        this.element.classList.remove('show');
        this.element.classList.remove('prefade');
        this.element.setAttribute('tabindex', '-1');

        const children = this.element.querySelectorAll('*');
        children.forEach((child) => {
            child.setAttribute('tabindex', '-1');
            const subChildren = child.querySelectorAll('*');
            subChildren.forEach((subChild) => {
                subChild.setAttribute('tabindex', '-1');
            });
        });

        if (this.previousFocus) {
            this.previousFocus.focus();
        }
    }

    isShow() {
        return this.element.classList.contains('show');
    }

    toggleVisibility() {
        const isVisible = this.isShow();
        this.element.classList.toggle('show');
        this.element.setAttribute('aria-hidden', String(!isVisible));
        this.element.setAttribute('tabindex', this.isShow() ? '0' : '-1');
        if (this.closeBtn) {
            this.closeBtn.setAttribute('tabindex', isVisible ? '0' : '-1');
        }

        if (this.timeout) {
            this.element.addEventListener('keydown', (e) => {
                if (e.key === 'Tab') {
                    e.preventDefault();
                    if (this.previousFocus) {
                        this.previousFocus.focus();
                    }
                }
                if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    this.toggleVisibility();
                }
            });
        }

        if (isVisible) {
            this.element.focus();
        } else {
            if (this.previousFocus) {
                this.previousFocus.focus();
            }
        }
    }

    /**
     * Create the html structure of the notification element
     */
    create() {
        this.element.classList.add('prefade');
        this.element.innerHTML = '';
        this.element.setAttribute('tabindex', '-1');
        this.element.setAttribute('aria-hidden', 'true');

        this.element.addEventListener('keydown', (e) => {
            if (e.key === 'Tab') {
                e.preventDefault();
                if (this.closeBtn) {
                    this.closeBtn.focus();
                }
            }
        });

        const baseId = this.element.id;
        const titleId = `${baseId}-title`;
        const bodyId = `${baseId}-body`;

        this.container = this.createElement('div', this.element, '', ['icon']);
        this.container.setAttribute('aria-label', 'Dismissible notification');
        this.titleTag = this.createElement('span', this.container, this.title, ['sc-font-m', 'sc-font-bold']);
        this.titleTag.id = titleId;
        const bodyElement = this.createElement('div', this.container, this.body);
        bodyElement.id = bodyId;
        this.element.setAttribute('role', 'alert');
        this.element.setAttribute('aria-labelledby', titleId);
        this.element.setAttribute('aria-describedby', bodyId);

        const triggerElem = document.querySelector(`[data-trigger="${this.element.id}"]`);
        if (triggerElem) {
            triggerElem.addEventListener('click', () => {
                this.previousFocus = document.activeElement;
                this.toggleVisibility();
            });
        }

        if (this.close) {
            this.closeBtn = this.createCloseButton();
        }
    }

    /**
     * @param {String} attribute
     * @param {String} value
     */
    update(attribute, value) {
        if ('class' === attribute){
            const isVisible = this.isShow();
            this.element.setAttribute('aria-hidden', String(!isVisible));
            this.element.setAttribute('tabindex', this.isShow() ? '0' : '-1');
            
            if (isVisible) {
                this.previousFocus = document.activeElement;

                setTimeout(() => {
                    this.element.focus();
                }, 50);

                this.element.focus();
            
                this.element.classList.remove('prefade');
                if (this.timeout) {
                    if(this.activeTimeout) {
                        window.clearTimeout(this.activeTimeout);
                    }
                    this.activeTimeout = window.setTimeout(this.hide.bind(this), this.timeout);
                }
            } else {
                this.hide();
            }
        } else if ('class' === attribute && !this.isShow()) {
            this.hide();
        }
        if ('title' === attribute) {
            this.titleTag.innerHTML = value;
        }
        if ('close' === attribute) {
            if (!this.closeBtn && 'true' === value) {
                this.closeBtn = this.createCloseButton();
            } else {
                this.closeBtn.remove();
                this.closeBtn = null;
            }
        }
    }

    /**
     * @param {String} name
     * @param {String} body
     * @param {Array} classes
     * @param {HTMLElement} parent
     * @returns {Element}
     */
    createElement(name, parent, body, classes = []) {
        let element = document.createElement(name);

        classes.forEach((cls) => {
            element.classList.add(cls);
        });
        element.innerHTML = typeof body === 'string' ? body : '';

        parent.appendChild(element);

        return element;
    }

    createCloseButton() {
        const closeBtn = this.createElement('button', this.container, '');
        closeBtn.setAttribute('aria-label', 'Close the alert button');
        closeBtn.setAttribute('tabindex', this.element.classList.contains('show') ? '0' : '-1'); 

        closeBtn.addEventListener('click', () => {
            this.hide();
            if (this.previousFocus) {
                this.previousFocus.focus();
            }
        });

        closeBtn.addEventListener('keydown', (e) => {
            if (e.key === 'Tab') {
                e.preventDefault();
                this.element.focus();
            } else if (e.key === 'Enter') {
                e.preventDefault();
                this.hide();
                if (this.previousFocus) {
                    this.previousFocus.focus();
                }
            }
        });

        const icon = this.createElement('as24-icon', closeBtn, '');
        icon.setAttribute('type', 'close');
        icon.setAttribute('aria-hidden', 'true');

        return closeBtn;
    }

}

document.addEventListener('DOMContentLoaded', () => {
    const notifications = document.querySelectorAll('as24-notification');
    notifications.forEach((notification) => {
        notification.setAttribute('tabindex', notification.classList.contains('show') ? '0' : '-1');
        const children = notification.querySelectorAll('*');
        children.forEach((child) => {
            child.setAttribute('tabindex', notification.classList.contains('show') ? '0' : '-1');
            const subChildren = child.querySelectorAll('*');
            subChildren.forEach((subChild) => {
                subChild.setAttribute('tabindex', notification.classList.contains('show') ? '0' : '-1');
            });
        });
    });
});

export default Notification;
